.minvoice-product-card .ant-card-cover {
    height: 30%;
    position: relative;
}

.minvoice-product-card .ant-card-body {
    height: 50%;
    position: relative;
    line-height: 2;
    text-overflow: ellipsis;
    overflow-y: scroll;
    text-align: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.minvoice-product-card .ant-card-body::-webkit-scrollbar {
    display: none;
}

.minvoice-product-card .minvoice-product-card-cover {
    width: 100%;
    height: 100%;
}

.ant-card-bordered {
    border: 1px solid #d8d8d8 !important;
}

.minvoice-product-card .minvoice-product-card-cover.minvoice-product-card-cover-contain {
    object-fit: contain;
    padding:  15px;
}

.minvoice-product-card .minvoice-product-card-cover.minvoice-product-card-cover-cover {
    object-fit: cover;
}