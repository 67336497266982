.logo {
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    height: 100%;

}

.logo img {
    object-fit: contain;
    width: unset;
}