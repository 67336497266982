
.nav {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    width: 100%;
    text-align: center;
}
.search-invoice-menu {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}